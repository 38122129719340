#app {
    box-sizing: border-box;
    padding: 0 0 230px;
    margin: 0;
    min-height: 100vh;
    position: relative;
}
.content-container  {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;

    @media only screen and (max-width:768px){
        padding: 0 20px;
    }
    @media only screen and (max-width:500px){
        padding: 0 10px;
    }
}
.centered-content {
    max-width: 500px;
    margin: 0 auto;
}
.padded {
    padding: 0 20px;
}
#main-header {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    margin: 0;
    .content-container {
        display: flex;
        justify-content: space-between;
    }
    a {
        color: $gray-color;
    }
}
#main-logo {
    img {
        height: 50px;
    }
}
#site-nav {
    .menu {
        font-size: 0.8em;
        padding: 0;
        margin: 0;
        list-style: none;
        line-height: 50px;
        li {
            display: inline-block;
            margin-left: 20px;
        }
    }
}
#app-main {
}
#site-footer {
    display: block;
    height: 170px;
    background: transparent url(/build/static/images/grab-driver-bg.jpg) no-repeat center top;
    background-size: auto 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.switch-button {
    background: $color-primary;
    color: $color-primary-tint;
    font-size: 1.4em;
    padding: 30px;
    border-radius: 10px;
    display: block;
    text-align: center;
    float: right;
    margin: 70px 0 0 0;

    &:hover {
        background: $color-secondary;
        color: $color-secondary-tint;
        text-decoration: none;
    }

    @media only screen and (max-width:991px){
        margin: 50px 0 0 0;
    }
    @media only screen and (max-width:567px){
        float: none;
        margin: 10px 0 30px;
        max-width: 350px;
        padding: 15px;
    }
}
.frame-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    text-align: center;
    margin: 0 0 40px;

    @media only screen and (max-width:991px){
        grid-template-columns: repeat(2,1fr);
        gap: 30px;
    }
    @media only screen and (max-width:567px){
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
    @media only screen and (max-width:375px){
        gap: 10px;
    }


    .frame-list-item {
        font-size: 1.8em;
        img {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
            margin: 0 0 10px;
        }
        a {
            color: $color-primary;
        }
        span {
            display: block;
            padding: 5px 0;
        }
    }
}

.login-hero {
    border-radius: 10px;
    padding: 30px 10px;
    text-align: center;
    margin: 20px 0 30px;
    background: $color-primary;
    color: $color-primary-tint;
    font-size: 1.4em;
    line-height: 1.5em;
    font-weight: normal;
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
.otp-input {
    background: #fff url(/build/static/images/otp-box.svg) repeat-x;
    width: 300px;
    background-size: 50px;
    height: 50px;
    overflow: hidden;
    margin: 10px auto;
    position: relative;
    input {
        letter-spacing: 32px;
        font-family: 'IBM Plex Mono', Courier, monospace;
        font-size: 30px;
        font-weight: bold;
        height: 50px;
        padding-left: 16px;
        padding-right: 0;
        width: 400px;
        border: 0;
        background-color: transparent;
        outline: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.btn-rounded {
    border-radius: 100px;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.btn-primary {
    color: #fff;
    text-decoration: none;
}
.btn-primary:hover {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}


.error-page-error {
    text-align: center;
    padding: 50px 0;
    color: #666;
    a {
        text-decoration: underline;
    }
}
