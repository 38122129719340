
#user-section {
    display: flex;
    background: #f1f1f1;
    margin: 0 0 20px;
    div {
        flex: 1;
        text-align: center;
        padding: 10px 0 6px;
        small {
            display: block;
            font-size: 0.7em;
            color: $gray-color;
        }
        strong {
            display: block;
            font-size: 1.1em;
            font-weight: normal;
        }
    }
    div:first-child {
        border-right: 1px solid #ddd;
    }
}

.steps-list {
    display: flex;
    padding: 15px 0 15px 0;
    margin: -2px 0 0 0;
    list-style: none;
    border-radius: 5px;
    line-height: 45px;
    height: 72px;
    overflow:hidden;
    position: relative;
    min-width: 0;
    z-index: 2;
    &:before {
        content: "";
        position: absolute;
        height: 2px;
        top: 15px;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 10;
    }
    &:after {
        content: "";
        position: absolute;
        height: 2px;
        top: 57px;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 10;
    }
    a {
        flex: 1;
        background: #e9e9e9;
        position: relative;
        text-align: center;
        font-weight: bold;
        font-size: 1.1em;
        text-decoration: none;
        padding: 0 5px 0 25px;
        min-width: 0;
        & > strong {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
            overflow: hidden;
            small {
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.8em;
                font-weight: normal;
                @media screen and (max-width:768px) {
                    display: none;
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            top: 0;
            right: -20px;
            border-left: 22px solid #fff;
            z-index: 1;
        }
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            top: 0;
            right: -18px;
            border-left: 22px solid #e9e9e9;
            z-index: 2;
        }
        &:last-child:before,&:last-child:after {
            display: none;
        }
        &:first-child {
            margin-left: -10px;
        }
        &.active {
            color: $color-primary !important;
        }

        &.pass {
            background: #D1F7DC;
            color: #222;
        }
        &.pass:after {
            border-top-color: #e9e9e9;
            border-bottom-color: #e9e9e9;
            border-left-color: #D1F7DC;
        }
        &.pass:after {
            border-top-color: transparent;
            border-bottom-color: transparent;
        }

        &.active {
            background: $color-primary;
            color: #fff !important;
        }
        &.active:after {
            border-top-color: #e9e9e9;
            border-bottom-color: #e9e9e9;
            border-left-color: $color-primary;
        }

        &.current > span {
            position: absolute;
            top: 0;
            right: 0;
            left: 18px;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
        }
        &.current > span:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: -15px;
            margin-left: -15px;
            left: 50%;
            z-index: 3;

            border-left: 12px solid transparent;
            border-right: 12px solid transparent;

            border-top: 12px solid $color-primary;
        }

        &.current > span:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: -15px;
            margin-left: -12px;
            left: 50%;
            z-index: 4;

            border-left: 9px solid transparent;
            border-right: 9px solid transparent;

            border-top: 9px solid #E9F7ED;
        }

    }
}
.step-types {
    display: flex;
    margin: -5px 0 30px;
    justify-content: space-between;
    color: $gray-color;
    font-size: 1em;
    line-height: 1.2em;
    & > div {
        padding: 0 0 5px;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            left: 0;
            height: 3px;
            width: 100%;
            border-radius: 3px;
        }
    }
    & > div:first-child {
        flex-basis: 65%;
        &:after {
            background: $color-primary;
        }
    }
    & > div:last-child {
        flex-basis: 34%;
        &:after {
            background: $gray-color;
        }
    }
}

.step-desc {
    background: #E9F7ED;
    border-radius: 5px;
    border: 2px solid $color-primary;
    text-align: center;
    padding: 5px 0 2px;
    margin: 20px 0 0;
    position: relative;
    z-index: 1;
    small {
        display: block;
        font-size: 0.7em;
        color: $gray-color;
    }
    strong {
        display: block;
        font-size: 1em;
        font-weight: normal;
    }
}

.step-content {
    .step-header {
        text-align: center;
        h1 {
            font-size: 0.8em;
            color: $gray-color;
            padding: 0;
            margin: 0;
            line-height: 1.4em;
        }
        h2 {
            font-size: 1.3em;
            color:$color-secondary;
            font-weight: bold;
        }
    }
    table {
        border: 1px solid #222;
        border-collapse: collapse;
        th {
            background: #ddd;
        }
        th,td {
            padding: 5px;
            border: 1px solid #222;
        }
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
}

.next-step {
    border: 2px solid $color-primary;
    background: #E9F7ED;
    padding: 10px 5px 0;
    border-radius: 5px;
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    font-size: 1em;
}
.step-car-type-list {
    max-width: 400px;
    margin: 0 auto;
    button {
        display: block;
        width: 100%;
        background: $color-primary;
        color: #fff;
        border-radius: 50px;
        font-size: 1.2em;
        line-height: 50px;
        height: 50px;
        padding: 0 5px 0 50px;
        position: relative;
        margin: 0 0 10px;
        border:none;
        outline: none;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
        span {
            display: block;
            position: absolute;
            background: #fff;
            color: $color-primary;
            width: 36px;
            height: 35px;
            border-radius: 36px;
            font-size: 0.9em;
            line-height: 38px;
            text-align: center;
            top: 7px;
            left: 7px;
        }
        &:hover {
            background: #000;
        }
    }
}

.step-carsize-cal {
    max-width: 500px;
    margin: 0 auto 40px;
}
