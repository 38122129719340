@font-face {
    font-family: 'Grab Community Solid WF';
    src: url('/build/static/fonts/grabcommunitysolid/grab_community_solid_th-bold-webfont.woff2') format('woff2'),
         url('/build/static/fonts/grabcommunitysolid/grab_community_solid_th-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Grab Community Solid WF';
    src: url('/build/static/fonts/grabcommunitysolid/grab_community_solid_th-medium-webfont.woff2') format('woff2'),
         url('/build/static/fonts/grabcommunitysolid/grab_community_solid_th-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
