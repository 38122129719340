@charset "UTF-8";

/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

$slick-font-path: "/theme/static/fonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/theme/static/images/" !default;
$slick-arrow-color: #666 !default;
$slick-dot-color: #fff !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 2;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 30px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: -10px;
    [dir="rtl"] & {
        left: auto;
        right: -10px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -10px;
    [dir="rtl"] & {
        left: -10px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 99;
    li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $color-primary;
        opacity: 0.2;
        border-radius: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
            }
        }
        &.slick-active {
            opacity: 1;
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#colorbox {box-shadow: 2px 2px 4px rgba(0,0,0,0.3);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#fff; opacity: 0.9; filter: alpha(opacity = 90);}
#colorbox{outline:0;}
#cboxContent{background:#fff; overflow:hidden;}
.cboxIframe{background:#fff;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadedContent{margin-bottom:20px;}
#cboxTitle{position:absolute; bottom:0px; left:0; text-align:center; width:100%; color:#999;}
#cboxCurrent{display: none;}
#cboxLoadingOverlay{background:#fff url(/theme/static/images/loading.gif) no-repeat center center}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
	border:0; padding:0 3px; margin:0; overflow:visible; width:auto; background:none;
}


#cboxPrevious {

}
#cboxNext {

}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

#cboxSlideshow{position:absolute; bottom:0px; right:42px; color:#444;}
#cboxPrevious{
	background:url(/theme/static/images/icons/chevron-left.svg) no-repeat center center;
	position:absolute; top:50%; left:10px; color:#444;
	width: 20px;
	height: 60px;
	margin-top: -30px;
	overflow: hidden;
	background-size: contain;
	text-indent: -999px;
	outline: none;
}
#cboxNext{
	background:url(/theme/static/images/icons/chevron-right.svg) no-repeat center center;
	position:absolute; top:50%; right:10px; color:#444;
	width: 20px;
	height: 60px;
	margin-top: -30px;
	overflow: hidden;
	background-size: contain;
	text-indent: -999px;
	outline: none;
}
#cboxClose{
	background:url(/theme/static/images/icons/times.svg) no-repeat center center;
	position:absolute; top:10px; right:10px; color:#444;
	width: 40px;
	height: 40px;
	margin-top: 0;
	overflow: hidden;
	background-size: contain;
	text-indent: -999px;
	outline: none;
}
