.home-status {
    border: 2px solid $color-primary;
    padding: 10px 20px;
    background: #E9F7ED;
    border-radius: 5px;
    margin: 0 0 20px;
}
.accordion-button {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0 1rem;
}

#resend {
    display: none;
}
