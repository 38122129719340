// Body
$body-bg: #fff;

// Typography
$font-family-general: 'Grab Community Solid WF','WF Kanit',sans-serif;
$font-family-heading: 'Grab Community Solid WF','WF Kanit', sans-serif;
$font-family-subheading: 'Grab Community Solid WF','WF Kanit',sans-serif;

$text-color: #191919;
$text-color-secondary: #000;

$gray-color: #767676;
$gray-background: #f2f2f2;
$gray-line: #ccc;
$color-pale: #EFF4F8;

$color-white: #FFFFFF;
// Panels
$color-primary: #00B14F;
$color-primary-tint: #ffffff;
$color-secondary: #0E4836;
$color-secondary-tint: #ffffff;
$color-tertiary: #ea7628;
$color-tertiary-tint: #ffffff;
$color-quaternary: #bf1d1d;
$color-quaternary-tint: #ffffff;

/* Bootstrap Var */
$primary: #00B14F;

$link-color: #191919;
