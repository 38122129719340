html,body {
    width: 100%;
}
body {
    font-family: $font-family-general;
    font-size: 16px;
    color: #222;
    line-height: 1.5em;
    background: $body-bg;
}
img {
	max-width: 100%;
}
a {
    color: $link-color;
	transition: color 0.3s;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
ul {
	padding: 0;
	margin: 0 0 1em 2em;
	list-style: disc;
}
li {
	position: relative;
}
nav li {
	list-style: none;
	padding: 0;
	margin: 0;
	background: none;
}
nav li:before {
	display: none;
}
i {
	font-weight: 200;
	font-style: normal;
}
hr {
	clear: both;
	margin: 0.5em 0;
}
h1,h2,h3,h4,h5,h6
.h1,.h2,.h3,.h4,.h5,.h6 {
	font-family: $font-family-heading;
	font-weight: normal;
	line-height: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1 {
	font-size: 2.8em;
    font-weight: bold;
}
h2 {
	font-size: 2.4em;
}
h3 {
	font-size: 1.6em;
}
h4 {
	font-size: 1.4em;
}
h5 {
	font-size: 1.2em;
    line-height: 1em;
    color: #727272;
}
h6 {
	font-size: 1.1em;
}

@media screen and (max-width: 768px) {
	h1 {
	font-size: 2.2em;
	}
	h2 {
		font-size: 2em;
	}
	h3 {
		font-size: 1.4em;
	}
	h4 {
		font-size: 1.3em;
	}
	h5 {
		font-size: 1.2em;
	}
	h6 {
		font-size: 1.1em;
	}
}

b,strong {
	font-weight: bold;
}
table p:last-child {
	margin-bottom: 0;
}
p {
    margin: 0 0 10px;
}
small {
    font-size: 0.8em;
    line-height: 1.2em;
}
canvas {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing:antialiased;
    text-rendering:optimizeLegibility;
}
